/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.MuiButton-root {
  border-radius: 50rem !important;
  text-transform: none !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.br-none {
  border-radius: 0 !important;
}

.br-none-first {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.br-none-last {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.fs-lg-1 {
  font-size: 3rem !important;
}

.child-mb-0 p {
  margin-bottom: 0 !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
}

.card {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
  border: none;
}

/* Utilities */
.grid-margin {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0;
  }
}

.max-h-60 {
  max-height: 70vh;
  overflow-y: auto;
}

.w-300 {
  width: 300%;
}

.max-w-100 {
  max-width: 100%;
  overflow-x: auto;
}

.line-break {
  white-space: pre-wrap;
}

.border-edit {
  border: 3px solid;
}
