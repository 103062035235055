.h-24 {
    /*height: 2.5rem;*/
    height: 2.5rem;
    margin-top: 6px;
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 16s;
    transform-style: preserve-3d;
    transform-origin: 40px 40px 0;
}
.cubespinner div {
    position: absolute;
    width: 100%;
    height: 80px;
    text-align: left;
}
.cubespinner .face1 {
    transform: translateZ(40px);
    -webkit-backface-visibility: hidden;
}
.cubespinner .face2 {
    transform: rotateX(120deg) translateZ(40px);
    -webkit-backface-visibility: hidden;
}
.cubespinner .face3 {
    transform: rotateX(240deg) translateZ(40px);
    -webkit-backface-visibility: hidden;
}

@keyframes spincube {
    from,
    to {
        transform: rotateX(0deg);
    }
    0%,
    25% {
        transform: rotateX(0deg);
    }
    33%,
    58% {
        transform: rotateX(120deg);
    }
    66%,
    92% {
        transform: rotateX(240deg);
    }
    100% {
        transform: rotateX(360deg);
    }
}


@media (max-width: 767px) {
    .cubespinner div {
        /*font-size: 32px;*/
    }
    .title_style {
        /*padding-top: 100px;*/
    }
}

@media (max-width: 426px) {
    .title_style {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }
}
