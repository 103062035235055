.MuiAutocomplete-listbox {
    display: flex;
    flex-wrap: wrap;
}

.custom-chip {
    border: 1px solid #0000FE !important;
    background: #EBEBFF !important;
    color: #2C2CFF !important;
    font-weight: bold;
    font-size: 0.68rem !important;
}

.custom-chip:hover {
    border: 1px solid #0000FE !important;
    background: #0000FE !important;
    color: #FFF !important;
    cursor: pointer;
}
.Mui-focused {
    background-color: #fff !important;
}
