.page-size {
    /*margin-left: 15px;*/
    /*margin-right: 45px;*/
    page-break-after: always;
    /*font-size: 0.3em;*/
    overflow: hidden;
    word-wrap: break-word;
}

 @media print {
     .page {
         margin-left: 15px;
         margin-right: 15px;
         page-break-after: always;
         font-size: 0.3em;
     }
 }
