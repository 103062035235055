.MuiDrawer-root > .MuiDrawer-paperAnchorBottom {
    height: calc(70vh - 56px);
    overflow: visible;
    right: 20px;
    left: 20px;
}

@media (min-width: 1024px) {
    .MuiDrawer-root > .MuiDrawer-paperAnchorBottom {
        right: 25%;
        left: 25%;
    }
}

@media (min-width: 768px) {
    .MuiDrawer-root > .MuiDrawer-paperAnchorBottom {
        right: 15%;
        left: 15%;
    }
}
