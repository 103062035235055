/** {
    background-color: #F9f9f9;
}

.content {
    width: 500px;
    margin: 0 auto;


}



.form-div {
    width: 600px;
    margin: 30px auto;
    border: 1px solid #dadada;
    border-radius: 3%;
    background-color: white;

}

form {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 500px;
    align-items: flex-start;
    margin: 0 auto;
    padding: 10px;


}


form div {
    margin: 10px;
}

.button{
    background-color: #1a237e;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px 20px;
}

input, select{
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 5px;
}


#comments{
    height: 100px;
    width: 200px
}

*/

h4 > .MuiChip-root {
    background: #0000FE;
    color: #fff;
}
