.MuiDrawer-paper {
    justify-content: space-between !important;
    flex-direction: row;
}

@media all and (max-width: 600px) {
    .MuiDrawer-paper {
        flex-direction: column-reverse !important;
        justify-content: flex-end !important;
    }
}
