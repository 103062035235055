.examplesNavList {
    list-style: none;
    padding-left: 0;
    flex-wrap: wrap;
    gap: 10px;
    flex: 1;
    justify-content: left;

}

.example {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    max-width: 1028px;
    margin: auto;
}

/*.example:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    outline: 2px solid rgba(115, 118, 225, 1);
}*/

.exampleText {
    padding: 7px 60px;
    font-size: 20px;
    color: #4942E4;
    cursor: pointer;
    /*width: 280px;
    height: 100px;*/
    
}

.exampleText:hover{
    color: #11009E;
    background-color: #ECF2FF;
    
}

.initialText{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 8px 20px;
    white-space: pre-line;
}

.initialTextContainer{
    display: flex;
    width: 100%;
}

@media only screen and (max-height: 780px) {
    .exampleText {
        font-size: 20px;
        height: 80px;
    }
}

/*@media (max-width: 426px) {
    .example {
        margin-left:15px;
        margin-right: 15px;
    }
}*/

@media (max-width: 769px) {
    .exampleText{
        font-size: 15px;
    }
    .example {
        padding: 12px;
    }
}

@media (max-width: 321px) {
    .initialText {
        font-size: 15px;
        padding: 4px 10px;
    }
    .exampleText {
        padding: 3px 30px;
        font-size: 15px;
    }
    .example {
        padding: 10px;
    }
}
