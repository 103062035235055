.text-description-gallery {
    color: #dadada !important;
    height: 60%;
    overflow-y: auto;
}

.content-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.content-scroll::-webkit-scrollbar:vertical {
    width:8px;
}

.content-scroll::-webkit-scrollbar-button:increment,.content-scroll::-webkit-scrollbar-button {
    display: none;
}

.content-scroll::-webkit-scrollbar:horizontal {
    height: 8px;
}

.content-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(134, 134, 134, 0.76);
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.content-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
}


@media (min-width: 768px) {
    *::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    *::-webkit-scrollbar:vertical {
        width:15px;
    }

    *::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button {
        display: none;
    }
    **::-webkit-scrollbar:horizontal {
        height: 15px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(129, 129, 129, 0.76);
        border-radius: 15px;
        border: 3px solid #ffffff;
        cursor: pointer !important;
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: rgba(68, 67, 67, 0.76);
    }

    *::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.58);
    }

}
