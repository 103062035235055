.fc-event {
    padding: 0 !important;
}

.custom-event {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 5px 8px;
    cursor: grab;
    width: 100%;
    color: #000;
    height: 100%;
    align-items: center;
}

.custom-event .post-time {
    font-size: 0.8rem;
    margin: 0 5px;
}

.custom-event .post-title {
    display: none;
    overflow: hidden;
}

.custom-event:hover {
    opacity: 0.8;
}

/** WEEK VIEW */
.fc-event-main .custom-event {
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

.post-image {
    display: none;
    width: 100%;
    height: 120px;
    object-fit: cover;
    object-position: center;
    background: black;
    opacity: 0.9;
    border-radius: 7px;
}

.fc-event-main .custom-event .post-icon-image{
    display: none;
}

.fc-event-main .custom-event .post-image {
    display: block;
}

.fc-event-main .custom-event .post-title {
    display: block;
}

.fc-timegrid-slot {
    height: 50px !important;
}

.fc .fc-button-group .fc-button {
    color: #000;
}

.bg-success, .bg-primary {
    color: #fff;
}


.custom-event {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
