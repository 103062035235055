.item-content {
    position: absolute;
    bottom: 45px;
    background: #fff6eb;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.13);
    right: -20px;
}

.item-toggle::after {
    content: "";
    background: #fff6eb;
    position: absolute;
    bottom: -10px;
    width: 10px;
    height: 10px;
    left: 35px;
    clip-path: polygon(0 0, 100% 0,50% 100%);
    box-shadow: 1px 2px 5px rgba(0,0,0,0.13);
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}
.animateHover .MuiSvgIcon-root {
    transition: font-size 170ms ease-out;
}

.animateHover .MuiSvgIcon-root:hover {
    font-size: 1.7rem !important;
}

.animateHover .MuiIconButton-root {
    text-align: center !important;
}

.h-menu-share {
    overflow-y: hidden !important;
}

.card-action-bottom {
    position: relative !important;
    top: -63px;
    padding: 10px 0;
}

/*.content-month:before,.content-month:after {*/
.content-month:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 30px;
    content: "";
    z-index: 2;
}

/*.content-month:before {
    top: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), #f3f5f8);
}*/

.content-month:after {
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #868686);
}
