.photo {
    box-sizing: border-box;
    padding: 4px;
    border: 1px solid #969595;
    margin: 2px;
}

.bg-photo {
    padding: 2px;
    border-radius: 50%;
}
