.img-ship {
    width: 100%;
    height: auto;
    position: relative;
    top: -50px;
}

.fs-sm {
    font-size: 0.72em;
}

.visx-axis-tick text {
    font-size: 8px !important;
}

@media (max-width: 768px) {
    .img-ship {
        position: relative;
        top: 0;
    }
}
