.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    /*margin-top: 20px;
    background-color: #e8ebfa*/
}

.chatRoot {
    flex: 1;
    display: flex;
}

.chatContainer {
    /*flex: 1;
    display: flex;
    flex-direction: column;*/
    align-items: center;
    width: 100%;
    position: relative;
    height: 84vh;
    background-color: #e8ebfa;
    padding: 15px;
    border-radius: 25px;
}

.chatEmptyState {
    flex-grow: 1;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*max-height: 71vh;*/
    /*padding-top: 60px;*/    
}

.chatEmptyStateTitle {
    font-size: 4rem;
    font-weight: 700;
    /*padding-top: 80px;*/
    text-align: left;
    word-break: keep-all;
    max-width: 1228px;
    line-height: 90px;
}

.chatEmptyStateTitleAnimated{
    font-size: 4rem;
    font-weight: 700;
    padding: 0px;
    /*text-align: center;*/
    word-break: keep-all;
    max-width: 1228px;
    line-height: 90px;
    color: rgba(115, 119, 225, 0.9);
    height: 200px;
    text-align: left;
}


.chatEmptyStateSubtitle {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 5px;
    max-width: 1228px;
    text-align: left;
    line-height: 30px;
    margin-bottom: 25px;
}

.startButton{
    text-align: center;
}

.chatMessageStream {
/*    flex-grow: 1;*/
    /*max-height: 1024px;*/
    max-width: 1028px;
    max-height: 71vh;
    height: 71vh;
    width: 100%;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.chatMessageGpt {
    margin-bottom: 20px;
    max-width: 50vw;
    display: flex;
    margin-left: 10px;
    /*min-width: 500px;*/
}

.chatMessageGptMinWidthLoading {
    max-width: 500px;
    /*max-width: 70px;*/
    /*margin-bottom: 20px;*/
}

.chatMessageGptMinWidth {
    /*max-width: 500px;*/
    max-width: 50vw;
    margin-bottom: 20px;
}

.chatInput {
    z-index: 1;
    position: fixed;
    bottom: 0;

    /*flex: 0 0 100px;*/
    /*padding-top: 12px;
    padding-bottom: 12px;*/
    padding: 10px 10px 30px 10px;
    width: 93%;
    right: 0;
    /*max-width: 1028px;*/
    /*background: #f2f2f2;*/
    /*margin-left: 15%;
    margin-right: 15%;
    margin: auto;*/

}

.chatAnalysisPanel {
    flex: 1;
    overflow-y: auto;
    max-height: 71vh;
    margin-left: 20px;
    margin-right: 20px;
}

.chatSettingsSeparator {
    margin-top: 15px;
}

.loadingLogo {
    font-size: 28px;
}

.commandsContainer {
    display: flex;
    align-self: flex-end;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.commandButton {
    margin-right: 20px;
    margin-bottom: 20px;
}

.btnBack {
    position: fixed !important;
    right: 10px;
    z-index: 10;
    top: 70px;
    margin-right: 20px;
    margin-bottom: 20px;
}

@media (max-width: 426px) {

    .chatInput {
        padding: 10px 0px 30px 0px;
        width: 98%;
    }

    .chatMessageStream {
        padding: 0 5px;
    }
    .chatMessageGpt {
        max-width: 75vw;
    }
    .chatEmptyStateTitle {
        font-size: 1.8rem;
        line-height: 35px;
        padding-right: 20px;
        padding-top: 0px;
        /*height: 0;*/
    }
    .chatEmptyStateSubtitle {
        font-size: 0.9rem;
        line-height: 20px;
        padding-left: 10px;
        padding-top: 70px;
        margin-bottom: 0px;
    }
    .chatEmptyState {
        padding-top: 10px;
    }
    .btnBack {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .chatEmptyStateTitleAnimated {
        padding-top: 78px;
        font-size: 1.8rem;
        line-height: 35px;
        padding-bottom: 130px;
    }
    .chatContainer {
        padding-top: 50px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
    }
}

@media (max-width: 376px) {
    .chatEmptyStateTitle {
        padding-top: 40px;
        font-size: 1.6rem;
        line-height: 30px;
        height: 50px;
        padding-right: 20px;
    }
    .chatEmptyStateTitleAnimated {
        padding-top: 60px;
        font-size: 1.6rem;
        line-height: 30px;
        padding-bottom: 130px;
    }
    .chatInput {
        padding: 10px 0px 30px 0px;
        width: 98%;
    }
    .chatEmptyStateSubtitle {
        font-size: 0.8rem;
        line-height: 20px;
    }
}

@media (max-width: 321px) {
    .chatEmptyStateTitle {
        font-size: 1.4rem;
        padding-top: 0px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .chatEmptyStateSubtitle {
        font-size: 0.8rem;
        padding: 10px 12px;
        line-height: 20px;
    }
    .chatEmptyStateTitleAnimated {
        font-size: 1.4rem;
        margin-bottom: 5px;
        padding-top: 10px;
        line-height: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .chatInput {
        padding: 10px 0px 30px 0px;
        width: 98%;
    }
}

@media only screen and (max-height: 780px) {
    .chatEmptyState {
        padding-top: 0;
    }

    .chatEmptyStateTitle {
        font-size: 3rem;
        margin-bottom: 0px;
    }
}

@media (max-width: 1025px) {
    .chatInput {
        width: 92%;
    }
    .chatEmptyStateTitle {
        font-size: 2.8rem;
        line-height: 60px;
        padding-left: 30px;
    }
    .chatEmptyStateTitleAnimated {
        font-size: 2.8rem;
        padding-left: 30px;
        line-height: 60px;
    }
    .chatEmptyStateSubtitle{
        padding: 0px 25px;
    }
}

@media (max-width: 769px){
    .chatInput {
        width: 86%;
    }
    .chatEmptyStateTitle {
        padding-left: 20px;
        /*padding-top: 100px;*/
        font-size: 2rem;
        line-height: 50px;
    }
    .chatEmptyStateSubtitle {
        font-size: 1.2rem;
        line-height: 30px;
    }
    .chatAnalysisPanel {
        flex: 1;
        overflow-y: auto;
        max-height: 71vh;
        margin-left: 0px;
        margin-right: 0px;
        z-index: -1;
    }
    .chatEmptyStateTitleAnimated {
        padding-left: 20px;
        font-size: 2rem;
        line-height: 50px;
        height: 160px;
    }
}
