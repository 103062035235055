.selectIndex {
    /*position: fixed !important;*/
    left: auto;
    z-index: 10;
    top: 70px;
    margin-left: 20px;
    margin-bottom: 20px;
}

@media (max-width: 426px) {
    .selectIndex {
        margin-left: 10px;
        margin-bottom: 20px;
    }
}
