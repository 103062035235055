.thoughtProcess {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    word-wrap: break-word;
    padding-top: 12px;
    padding-bottom: 12px;
}

.closeBtn {
    position: absolute !important;
    top: 0;
    right: 0;
    margin-right: 25px !important;
    z-index: 10;
}

.pdfZone{
    position:relative;
    /*flex:1;*/
    height: 84vh;
    background-color:#e8ebfa;
    border-radius: 10px;
}

@media (max-width: 769px) {
.pdfZone {
        position: absolute;
        flex: 0;
        width: 85%;
        padding-top: 0;
    }
}
