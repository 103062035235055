.content-scroll {
    display: flex;
    max-height: 80vh;
}
.scroll-vertical {
    overflow-y: auto;
}

.card-selected {
    background: rgba(20, 168, 40, 0.56);
}
