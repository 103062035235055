.container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    max-width: 60vw;
    margin-left: auto;
    margin-right: 10px;
    align-items: center;
}

.message {
    position: relative;
    padding: 20px;
    background: #e8ebfa;
    border-radius: 15px;
    /*box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);*/
    outline: transparent solid 1px;
    max-width: calc(100% - 40px);
    word-wrap: break-word;
    white-space: pre-line;
}
/*.message::before {
    content: '';
    position: absolute;
    top: 0;
    right: -15px;
    width: 20px;
    height: 10px;
    border-left: 20px solid #e8ebfa;
    border-bottom: 15px solid transparent;
    z-index: 0;
}*/
