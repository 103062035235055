.questionContainer {
    display: flex;
    width: 100%;
    max-width: 1028px;
    margin-left: auto;
    margin-right: auto;
}

.questionInputContainer {
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    height: auto;
    width: 100%;
    background: white;
    padding: 0 10px;
}

.questionInputTextArea {
    width: 100%;
    line-height: 40px;
}

.questionInputTextArea textarea {
    overflow-y: auto !important;
    max-height: 60vh !important;
    padding: 10px;
    padding-top: 20px;
    font-size: 16px;
}

.questionInputButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}

.questionInputSendButton {
    cursor: pointer;
    padding: 15px;
}

.questionInputSendButtonDisabled {
    opacity: 0.4;
}

