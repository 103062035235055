.answerContainer {
    position: relative;
    max-width: 60vw;
    padding: 20px;
    background: rgb(249, 249, 249);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    outline: transparent solid 1px;
}

.answerContainerLoading {
    position: relative;
    padding: 15px 0px;
    /*background: rgb(249, 249, 249);
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);*/
    outline: transparent solid 1px;
}

/*.answerContainer::after {
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    width: 20px;
    height: 10px;
    border-right: 20px solid rgb(249, 249, 249);
    border-bottom: 15px solid transparent;
}*/


.answerLogo {
    font-size: 28px;
}

.answerText {
    font-size: 1.1rem !important;
    /*font-size: .875rem;*/
    letter-spacing: 0.03em;
    word-spacing: 0.03em;
    font-weight: 400;
    line-height: 22px;
    padding-top: 16px;
    padding-bottom: 16px;
    white-space: pre-line;
}

.insightText {
    font-size: .875rem !important;
}

.answerText p {
    font-size: 1.1rem !important;
}

.answerTextCursor::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 16px;
    background-color: #595757;
    animation: blink 0.8s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.answerTextLoading {
    font-size: 16px;
    font-weight: 400;
}

.answerText table {
    /*border-collapse: collapse;
    border-radius: 15px;
    overflow: hidden;*/
}

.answerText td,
.answerText th {
    /*font-size: .875rem !important;*/
    font-size: 1.1rem !important;
    /*border: 1px solid;
    padding: 5px;
    background: #ddd;
    border-bottom: 2px solid white;*/
}

.selected {
    outline: 2px solid rgba(115, 118, 225, 1);
}

.citationLearnMore {
    margin-right: 5px;
    font-weight: 600;
    line-height: 24px;
}

.citation {
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    padding: 0px 8px;
    background: #d1dbfa;
    color: #123bb6;
    text-decoration: none;
    cursor: pointer;
}

.citation:hover {
    text-decoration: underline;
}

.insights {
    margin-top: 20px;
    /*background: rgba(0, 0, 254, 0.03);*/
    background: rgb(249, 249, 249);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    padding: 15px;
    border-radius: 15px;
    max-width: 40vw;
}

.followupQuestionsList {
    margin-top: 10px;
}

.followupQuestionLearnMore {
    margin-right: 5px;
    font-weight: 600;
    line-height: 24px;
}

.followupQuestion {

    /*font-weight: 600;*/
    letter-spacing: 0.03em;
    word-spacing: 0.03em;

    line-height: 24px;
    text-align: center;
    border-radius: 10px;
    padding: 0px 8px;
    /*background: rgba(0, 0, 254, 0.05);*/
    background: #f5f5f5;
    /*background: rgba(28, 131, 234, 0.22);*/
    /*color: black;*/
    color: #0000FE;
    border: 1px solid #0000FE;
    /*font-style: italic;*/
    text-decoration: none;
    cursor: pointer;
}

.followupQuestion:hover {
    text-decoration: none;
    background: rgba(241, 241, 241, 0.5);
    color: #0000FE;
}

.supContainer {
    text-decoration: none;
    cursor: pointer;
}

.supContainer:hover {
    text-decoration: underline;
}

sup {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    vertical-align: top;
    top: -1;
    margin: 0px 2px;
    min-width: 14px;
    height: 14px;
    border-radius: 3px;
    background: #d1dbfa;
    color: #123bb6;
    text-decoration-color: transparent;
    outline: transparent solid 1px;
    cursor: pointer;
}

.retryButton {
    width: fit-content;
}

@keyframes loading {
    0% {
        content: "";
    }
    25% {
        content: ".";
    }
    50% {
        content: "..";
    }
    75% {
        content: "...";
    }
    100% {
        content: "";
    }
}

.loadingdots::after {
    content: "";
    animation: loading 1s infinite;
}

@media (max-width: 769px) {
.citation {
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        border-radius: 4px;
        padding: 0px 8px;
        background: #d1dbfa;
        color: #123bb6;
        text-decoration: none;
        cursor: pointer;
    }
    .answerContainer {
        max-width: 70vw;
    }
    .insights {
        max-width: 60vw;
    }
}
