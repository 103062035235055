.card-area-animation {
    transform: scale(1);
    transition: 200ms ease-in-out !important;
}
.card-area-animation:hover {
    transform: scale(1.1);
    background: rgba(236, 44, 147, 0.03);
}

.price {
    font-weight: 600;
}

.MuiListItemText-multiline {
    margin: 0;
}
