.base {
    line-height: 1.6;
    white-space: pre-wrap;
    transition: margin 300ms ease;
}

.base span {
    /* display: inline-block; */
    line-height: 1.4;
}

.entering {
    margin-bottom: -2.6em;
}

.match {
    border-radius: 5px;
    color: #fff;
    padding: 0 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.44);
    cursor: pointer
}
