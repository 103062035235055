.MuiDialogActions-root{
    display: flex;
    flex-direction: column;
}

.successBlock{
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.MuiPickerStaticWrapper-root > .MuiDialogActions-root {
    display: none;
}
